
  import { defineComponent } from 'vue'
  export default defineComponent({
    name: 'pageTitle',
    props: ["title"],
    data(){
      return {
        
      }
    },
    methods: {
      
    }
  })
