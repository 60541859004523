import { creates } from './index';
import { OMUrl,OPUrl } from '@/config/index';

//智慧营销

//获取当前门店banner列表
export function getBannerList(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getBannerList',
    method: 'GET',
    params: params
  })
}

//新增门店banner
export function createBanner(data: object){
  return creates({
    url: OMUrl + '/cs/skin/createBanner',
    method: 'POST',
    data: data
  })
}

//获取banner详情
export function getBannerDetail(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getBannerDetail',
    method: 'GET',
    params: params
  })
}

//编辑保存门店banner
export function editBanner(data: object){
  return creates({
    url: OMUrl + '/cs/skin/editBanner',
    method: 'POST',
    data: data
  })
}

//删除banner
export function delBanner(params: object){
  return creates({
    url: OMUrl + '/cs/skin/delBanner',
    method: 'GET',
    params: params
  })
}

//获取当前品牌详情及套餐列表
export function getBrandSkin(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getBrandSkin',
    method: 'GET',
    params: params
  })
}

//保存套餐
export function saveCompose(data: object){
  return creates({
    url: OMUrl + '/cs/skin/saveCompose',
    method: 'POST',
    data: data
  })
}

//获取次卡活动列表
export function getOnceCardList(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getOnceCardList',
    method: 'GET',
    params: params
  })
}

//获取财升次卡列表
export function getCsCardList(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getCsCardList',
    method: 'GET',
    params: params
  })
}

//新增门店次卡活动
export function createOnceCard(data: object){
  return creates({
    url: OMUrl + '/cs/skin/createOnceCard',
    method: 'POST',
    data: data
  })
}

//获取次卡活动详情
export function getOnceCardDetail(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getOnceCardDetail',
    method: 'GET',
    params: params
  })
}

//编辑门店次卡活动
export function editOnceCard(data: object){
  return creates({
    url: OMUrl + '/cs/skin/editOnceCard',
    method: 'POST',
    data: data
  })
}

//删除门店次卡活动
export function delOnceCard(params: object){
  return creates({
    url: OMUrl + '/cs/skin/delOnceCard',
    method: 'GET',
    params: params
  })
}

//获取活动列表
export function getActivityList(params: object){
  return creates({
    url: OPUrl + '/admin/activity/getActivityList',
    method: 'GET',
    params: params
  })
}

//新增活动
export function addActivity(data: object){
  return creates({
    url: OPUrl + '/admin/activity/addActivity',
    method: 'POST',
    data: data
  })
}

//删除活动
export function delActivity(params: object){
  return creates({
    url: OPUrl + '/admin/activity/delActivity',
    method: 'GET',
    params: params
  })
}

//获取活动详情
export function getActivityDetail(params: object){
  return creates({
    url: OPUrl + '/admin/activity/getActivityDetail',
    method: 'GET',
    params: params
  })
}

//编辑活动
export function editActivity(data: object){
  return creates({
    url: OPUrl + '/admin/activity/editActivity',
    method: 'POST',
    data: data
  })
}

//查询有效的优惠卷列表 财升
export function getCouponList(data: object){
  return creates({
    url: OMUrl + '/api/skin/getCouponList',
    method: 'POST',
    data: data
  })
}

//自定义添加广告位
export function addAdsense(data: object){
  return creates({
    url: OPUrl + '/admin/adsense/addAdsense',
    method: 'POST',
    data: data
  })
}

//获取自定义场所广告位列表
export function getAdsenseList(params: object){
  return creates({
    url: OPUrl + '/admin/adsense/getAdsenseList',
    method: 'GET',
    params: params
  })
}

//自定义修改广告位
export function editAdsense(data: object){
  return creates({
    url: OPUrl + '/admin/adsense/editAdsense',
    method: 'POST',
    data: data
  })
}

//删除自定义广告位
export function delAdsense(data: object){
  return creates({
    url: OPUrl + '/admin/adsense/delAdsense',
    method: 'POST',
    data: data
  })
}